import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { TabTitle } from '../Config/TitleName';
import { useParams } from 'react-router-dom';
import Slideshow from './Slideshow';

const ProjectPage = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`
const AppContainer = styled.div`
  position: relative;
  padding: 22px 20px;
  margin: auto;
  @media (min-width: 992px) { max-width: 960px; }
  @media (min-width: 1200px) { max-width: 1140px; }
`

const Col = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  @media (max-width: 900px) { flex-direction: row; justify-content: flex-start; }
  @media (max-width: 590px) { flex-direction: column; }
`

const ProjectLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
`
const ProjectLogoImage = styled.img`
  width: 200px;
`
const ProjectContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ProjectTitle = styled.div`
  color: var(--p-txt-c);
  padding: 20px 30px;
`
const ProjectButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 20px 10px;
  @media (max-width: 425px) { grid-template-columns: repeat(1, 1fr); }
`
const LinkButton = styled.a`
  display: inline-block;
  padding: 10px 20px;
  font-size: 1em;
  text-align: center;
  color: #fff;
  background-color: var(--p-c);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  text-decoration: none;
  margin: 5px 8px;
  &:hover {
    background-color: var(--s-c);
  }
`

const ProjectImages = styled.div`
  display: inline-flex;
  width: 100%;
  overflow-x: auto;
`

const ProjectImage = styled.img`
  width: 300px;
`

const ProjectDescription = styled.div`
  color: var(--p-txt-c);
  padding: 40px 30px;
`

const ProjectNotFind = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: var(--p-txt-c);
`

const Project = () => {
  const { link } = useParams();
  const { t } = useTranslation("global");
  const [slideshowOpen, setSlideshowOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const projects = t('portfolio.projects.items', { returnObjects: true });
  const project = projects.find(p => p.link === link);

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setSlideshowOpen(true);
  };

  if (!project) {
    return <ProjectNotFind>Project not found</ProjectNotFind>;
  }

  TabTitle(link);

  return (
    <ProjectPage>
      <AppContainer>
          <Col>
              <ProjectLogo>
                  <ProjectLogoImage src={project.image} alt={project.title} />
              </ProjectLogo>
              <ProjectContent>
                  <ProjectTitle>
                      <h1>{project.title}</h1>
                      <h5>{project.type}</h5>
                      <h5>{project.date}</h5>
                  </ProjectTitle>
                <ProjectButtons>
                    <LinkButton href={project.download} target="_blank" rel="noopener noreferrer">Download</LinkButton>
                    <LinkButton href={project.github} target="_blank" rel="noopener noreferrer">GitHub</LinkButton>
                    {project.testing && <LinkButton href={project.testing}>Testing</LinkButton>}
                </ProjectButtons>
              </ProjectContent>
          </Col>
          <ProjectImages>
            {project.images.map((img, idx) => (
              <ProjectImage
                key={idx}
                src={img}
                alt={`Project Image ${idx + 1}`}
                onClick={() => handleImageClick(idx)}
              />
            ))}
          </ProjectImages>
          <ProjectDescription>
              <h2>Description</h2>
              <p>{project.description}</p>
          </ProjectDescription>
          <Slideshow
            images={project.images}
            isOpen={slideshowOpen}
            onClose={() => setSlideshowOpen(false)}
            startIndex={currentImageIndex}
          />
      </AppContainer>
    </ProjectPage>
  )
}

export default Project;
